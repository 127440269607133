.container {
  margin: 50px;
  text-align: center;
}
.container img {
  width: 200px;
}
.container h1 {
  font-family: monospace;
  font-size: 42;
  color: #211747;
}
.container textarea {
  width: 90%;
  height: 15%;
  min-width: 50%;
  max-width: 90%;
  border-color: #211747;
  background-color: #f3f3f6;
}
#submit-btn {
  margin: 20px;
}
#submit {
  border: 0;
  text-align: center;
  display: inline-block;
  padding: 14px;
  width: 150px;
  margin: 7px;
  color: #ffffff;
  background-color: #2cc84d;
  border-radius: 8px;
  font-family: "proxima-nova-soft", sans-serif;
  font-weight: 600;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
}
#reset {
  border: 0;
  text-align: center;
  display: inline-block;
  padding: 14px;
  width: 150px;
  margin: 7px;
  color: #ffffff;
  background-color: #2cc84d;
  border-radius: 8px;
  font-family: "proxima-nova-soft", sans-serif;
  font-weight: 600;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
}
.beacons {
  display: inline-block;
  margin: 5px;
  border: 1px solid black;
  height: auto;
}
.item_key {
  color: #211747;
  display: table-cell;
  padding: 5px;
  min-width: 40px;
  vertical-align: middle;
  word-wrap: break-word;
  border-right: 1px solid black;
  background-color: #f3f3f6;
  text-align: center;
  font-weight: 800;
}
.item_value {
  display: table-cell;
  padding: 5px 5px;
  min-height: 18px;
  vertical-align: middle;
  background-color: #fff;
  text-align: left;
  word-wrap: break-word;
}
.border-line {
  border: 1px solid black;
}
.beacons h2.beacon-id {
  display: inline-block;
  padding-left: 10px;
  min-width: 300px;
}
.expand-beacon {
  margin: 20px;
}
.flex-container {
  display: flex;
}
.flex-child {
  flex: 1;
}
